import React from "react";
import ChevronRightIcon from "../icons/ChevronRight";
import HomeIcon from "../icons/Home";



const Breadcrumb = ({id, pages, textColor, hoverColor}) => {
    let aTextColor = "text-gray-500";
    if(textColor.length > 0){
      aTextColor = textColor;
    }
    let ahoverTextColor = "text-gray-700";
    if(hoverColor.length > 0){
      ahoverTextColor = hoverColor;
    }
    return (
      <nav className="flex  pr-10 md:pr-0 max-w-[calc(100vw-30px)] overflow-x-auto " aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4 px-2 md:px-0  " >
          <li>
            <div>
              <a href="/" className={`${aTextColor} ${ahoverTextColor}`}>
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page, index) => (
            <li key={`${index}-${id}-${page.name}`} >
              <div className="flex items-center text " >
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a
                  href={page.href}
                  className={`ml-4 text-sm font-medium max-w-[200px] truncate  ${aTextColor} ${(page.current)?'disabled pointer-events-none':(ahoverTextColor)} `}
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    );
};


export default Breadcrumb;




import React, { useState, useEffect } from "react";
import { isAuthorised, getJwtToken, destroyToken } from "./auth";
import { xml2json } from 'xml-js';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

export const send = async ({secured, endPoint, method}, formData = {}) => {
    let config = {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
    };
    if(method.toLowerCase() != "get"){
        config['body'] = JSON.stringify(formData);
    }
    if(secured === true){
        let token = getJwtToken();
        if(token && token.length > 0){
            config.headers['Authentication']  =  `Bearer ${token}`;
            config['credentials'] = "include";
        }
    }
    const endPointUrl  = BASE_URL + endPoint;

    try {
        const response = await fetch(endPointUrl, config);
        if (!response.ok) {
            const errorData = await response.json();
            if(errorData && errorData.message && errorData.message.toLowerCase().indexOf("token") >= 0){
                // something is wrong with the token, so we log out the user
                destroyToken();
                const msg = "Session error, refreshing the page";
                console.error(msg);
                //  refresh the page
                 window.location.reload();
                return new Error(msg);
            }else{
                let message = `An error has occured: ${response.status}`;
                if(errorData && errorData.message){
                    message = errorData.message;
                }
                return new Error(message);
            }
        }

        const data = await response.json();
        if (response.status != 200) {
            return new Error(data.message);
        }
        return data;
    } catch (error) {
        console.log("error", error);
        return new Error("Invalid server response format");
    }
}

export const post = async (endPointConfig, formData) => {
    endPointConfig = {
        ... endPointConfig,
        method: "POST"
    }
    const response  = await send(endPointConfig, formData);
    return response;
}

export const put = async (endPointConfig, formData) => {
    endPointConfig = {
        ... endPointConfig,
        method: "PUT"
    }
    const response  = await send(endPointConfig, formData);
    return response;
}

export const get = async (endPointConfig, formData) => {
    endPointConfig = {
        ... endPointConfig,
        method: "GET"
    }
    console.log("Here is it");
    const response  = await send(endPointConfig, formData);
    console.log(`Here is the respobse ${response}`);
    return response;
}

export const getCommunities = (onSuccess, onError) => {
    let endPoint = `${BASE_URL}/communities/active`;
    const isAdmin = isAuthorised(["admin"]);
    if (isAdmin) {
        endPoint = `${BASE_URL}/communities/all`;
    }
    get(endPoint, onSuccess, onError);
};


export const fetchFeed = async (endPoint, secured = false) => {
    let config = {
        method: "get",
        // headers: {
        //     "Content-Type": "application/json",
        // },
    };
    if(secured === true){
        let token = getJwtToken();
        if(token && token.length > 0){
            config.headers['Authentication']  =  `Bearer ${token}`;
            config['credentials'] = "include";
        }
    }

    const corsProxy = "https://cors.eu.org/";
    const endPointUrl  = corsProxy + endPoint;

    try {
        const response = await fetch(endPointUrl, config);
        if (!response.ok) {
            const errorData = await response.json();
            let message = `An error has occured: ${response.status}`;
            if(errorData && errorData.message){
                message = errorData.message;
            }
            return new Error(message);
        }

        const xmlTextData = await response.text();
        if (response.status != 200) {
            return new Error(xmlTextData.message);
        }
        const data = xml2json(xmlTextData, { compact: true, spaces: 4});
        return JSON.parse(data);
    } catch (error) {
        console.log("error", error);
        return new Error("Invalid server response format");
    }
}

export const addEventRules = (inputs) => { 

    let rules = {
        title: {
            errors: [],
            value: inputs.title,
            isValid: null,
            validations: {
                required: "Title is required",
                maxLength: {
                    value: 300,
                    error: "Event Title cannot be more than 300 characters"
                }
            }
        },
        externalLink: {
            errors: [],
            value: inputs.externalLink,
            isValid: null,
            validations: {
                required: "External Link is required",
                maxLength: {
                    value: 500,
                    error: "External Link cannot be more than 500 characters"
                }
            }
        },
        eventType: {
            errors: [],
            value: inputs.eventType,
            isValid: null,
            validations: {
                required: "Event Type is required",
                maxLength: {
                    value: 100,
                    error: "Event Type cannot be more than 100 characters"
                }
            }
        },
        locationType: {
            errors: [],
            value: inputs.locationType,
            isValid: null,
            validations: {
                required: "Location Type is required",
                maxLength: {
                    value: 100,
                    error: "Location Type cannot be more than 100 characters"
                }
            }
        },
        startDate: {
            errors: [],
            value: inputs.startDate,
            isValid: null,
            validations: {
                required: "Start Date is required",
                maxLength: {
                    value: 30,
                    error: "Start Date cannot be more than 30 characters"
                }
            }
        },
        endDate: {
            errors: [],
            value: inputs.endDate,
            isValid: null,
            validations: {
                required: "End Date is required",
                maxLength: {
                    value: 30,
                    error: "End Date cannot be more than 30 characters"
                }
            }
        },
        timezone: {
            errors: [],
            value: inputs.timezone,
            isValid: null,
            validations: {
                required: "Timezone is required",
                maxLength: {
                    value: 100,
                    error: "Timezone cannot be more than 100 characters"
                }
            }
        },
        description: {
            errors: [],
            value: inputs.description,
            isValid: null,
            validations: {
                required: "Description is required",
                maxLength: {
                    value: 2000,
                    error: "Description cannot be more than 2000 characters"
                }
            }
        },
        media: {
            errors: [],
            value: inputs.media,
            isValid: null,
            validations: {
                required: "Event picture is required",
                maxLength: {
                    value: 1000,
                    error: "Event picture url has to be less than 1000"
                }
            }
        }
    };

    if(inputs.locationType !== "virtual"){
        rules = {
            ... rules,
            state: {
                errors: [],
                value: inputs.address.state,
                isValid: null,
                validations: {
                    required: "State is required",
                    maxLength: {
                        value: 100,
                        error: "State: cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 5,
                    //     error: "State: at least 5 characters are required"
                    // }
                }
            },
            city: {
                errors: [],
                value: inputs.address.city,
                isValid: null,
                validations: {
                    required: "City is required",
                    maxLength: {
                        value: 100,
                        error: "City: cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 5,
                    //     error: "City: at least 5 characters are required"
                    // }
                }
            },
            zipcode: {
                errors: [],
                value: inputs.address.zipcode,
                isValid: null,
                validations: {
                    required: "Zipcode is required",
                    maxLength: {
                        value: 20,
                        error: "Zipcode: cannot be more than 20 characters"
                    },
                    // minLength: {
                    //     value: 5,
                    //     error: "Zipcode: at least 5 characters are required"
                    // }
                }
            }
        }
    }

    if(inputs.isRecurring === "true"){
        rules["recurringEvery"] = {
            errors: [],
            value: inputs.recurringEvery,
            isValid: null,
            validations: {
                maxLength: {
                    value: 50,
                    error: "Recurring Every: cannot be more than 50 characters"
                }
            }
        }
    }

    rules["hostName"] = {
        errors: [],
        value: inputs.hostName,
        isValid: null,
        validations: {
            maxLength: {
                value: 100,
                error: "Hosted By: cannot be more than 100 characters"
            }
        }
    }

    return rules;
}
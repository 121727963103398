import React, { useRef, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import states from "../utils/states.json";
import { addJob, editJob } from "../core/job_listing";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { getJobDetails } from "../core/job_listing";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import Industries from "../components/utils/industries";
import { useNavigate } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";


const JobForm = ({ isEdit = false }) => {

    const navigate = useNavigate();

    const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE;
    const DEFAULT_LOGO = "/defaultCompanyLogo.png";

    const [editorKey, setEditorKey] = useState(4);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [jobTitle, setJobTitle] = useState("");
    const [jobId, setJobId] = useState("");
    const [compesation, setCompesation] = useState("");
    const [jobType, setJobType] = useState("");
    const [locationType, setLocationType] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [industry, setIndustry] = useState("");
    const [status, setStatus] = useState("");
    const [callToActionLink, setCallToActionLink] = useState("");
    const [isVolunteer, setIsVolunteer] = useState("no");
    // details value props
    const editorSkillsRef = useRef(null);
    const [skills, setSkills] = useState(``);
    const editorQualifaictionsRef = useRef(null);
    const [qualifications, setQualifications] = useState(``);
    const editorSpecialAccomodationsRef = useRef(null);
    const [specialAccomodations, setSpecialAccomodations] = useState(``);
    const editorDetailsRef = useRef(null);
    const [details, setDetails] = useState(``);

    // Editing a job
    let { id } = useParams();
    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    let editJobId = "";
    const [isOwner, setIsOwner] = useState(false);
    const [job, setJob] = useState();
    const [cleanHtml, setCleanHtml] = useState("");
    const [cleanQualificationsHtml, setCleanQualificationsHtml] = useState("");
    const [cleanSkillsHtml, setCleanSkillsHtml] = useState("");
    const [cleanAccomodationsHtml, setCleanAccomodationsHtml] = useState("");

    useEffect(() => {
        const fetchJobDetails = async (id) => {
            const job = await getJobDetails(id);
            setJob(job)

            if (loggedInUserStr) {
                const jobUserId = job.user._id;
                const loggedinUserId = loggedInUser._id;
                setIsOwner(jobUserId == loggedinUserId);
            }

            const details = job.job_details.details;
            const decodedDetails = atob(details);
            let html = DOMPurify.sanitize(decodedDetails, {
                USE_PROFILES: { html: true },
            });
            html = decodeURIComponent(html);
            setCleanHtml(html);

            //qualifications
            const qualifications = job.job_details.qualifications;
            const decodedQualifications = atob(qualifications);
            let qualificationsHtml = DOMPurify.sanitize(decodedQualifications, {
                USE_PROFILES: { html: true },
            });
            qualificationsHtml = decodeURIComponent(qualificationsHtml);
            setCleanQualificationsHtml(qualificationsHtml);

            //skills
            const skills = job.skills;
            // console.log("Skills:", decodeURIComponent(skills));
            const decodedSkills = decodeURIComponent(skills); //atob(decodeURIComponent(skills));
            let skillsHtml = DOMPurify.sanitize(decodedSkills, {
                USE_PROFILES: { html: true },
            });
            setCleanSkillsHtml(skillsHtml);

            //accomodations
            const accomodations = job.job_details.special_accomodations;
            const decodedAccomodations = atob(accomodations);
            let accomodationHtml = DOMPurify.sanitize(decodedAccomodations, {
                USE_PROFILES: { html: true },
            });
            accomodationHtml = decodeURIComponent(accomodationHtml);
            setCleanAccomodationsHtml(accomodationHtml);

            //update the form state
            setJobTitle(job.title);
            setJobId(job.job_id);
            setCompesation(job.compesation);
            setJobType(job.job_type);
            setLocationType(job.location_type);
            setState(job.location.state);
            setCity(job.location.city);
            setZipcode(job.location.zipcode);
            setIndustry(job.industry);
            setStatus(job.display_status);
            setCallToActionLink(job.call_to_action_link);
            setEditorKey(editorKey * 2);
            setSkills(skillsHtml);
            setQualifications(qualificationsHtml);
            setSpecialAccomodations(accomodationHtml);
            setDetails(html);
            setIsVolunteer(job?.isVolunteer??"no");
        }
        if (id) {
            fetchJobDetails(id);
        }
    }, []);

    const clearForm = () => {
        setJobTitle("");
        setJobId("");
        setCompesation("");
        setJobType("");
        setLocationType("");
        setState("");
        setCity("");
        setZipcode("");
        setIndustry("");
        setStatus("");
        setCallToActionLink("");
        setEditorKey(editorKey * 2);
        setSkills("<p></p>");
        setQualifications("<p></p>");
        setSpecialAccomodations("<p></p>");
        setDetails("<p></p>");
        setIsVolunteer("no");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            jobTitle,
            jobId,
            compesation,
            jobType,
            locationType,
            state,
            city,
            zipcode,
            industry,
            status,
            callToActionLink,
            skills,
            qualifications,
            specialAccomodations,
            details,
            isVolunteer
        };
        let response = null;
        if (id) {
            //editing context
            inputs['id'] = id;
            response = await editJob(inputs);
            setIsLoading(false);
            if (response !== true) {
                return setErrors(response);
            }
            toast.success('Your Job was edited successfully', {
                icon: '✅'
            });
            navigate(`/jobs/${id}`);
        } else {
            //normal post
            response = await addJob(inputs);
            setIsLoading(false);
            if (response !== true) {
                return setErrors(response);
            }
            clearForm();
            toast.success('Your Job was posted successfully', {
                icon: '✅'
            });
            navigate("/jobs")
        }

    }

    const [maximumLength, setMaximumLength] = useState(140000);
    const handleEditorUpdate = (text, editor, setter) => {
        const length = editor.getContent({ format: 'text' }).length;
        if (length <= maximumLength) {
            setter(text);
        }else{
            toast.error('Maximum content length exceeded. Accepts upto 20,000 words', {
                icon: '🚫',
                duration: 1500
            });
        }
    };

    return (
        <MainLayout currentPage={"addjob"} >
            <div className="flex-grow flex flex-col  ">
                <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md drop-shadow-sm mb-20 md:mb-0">
                    <div className="space-y-12">
                        <div className="border border-gray-900/10 rounded-md ">
                            <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">{`${id ? 'Edit' : 'Post New'}`} Job Form</h2>
                            <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                Fill out and submit this form to {`${id ? 'edit a' : 'add a new'}`} job to the job listing.
                            </p>

                            <div className="mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">

                                {/* job id, title */}
                                <div className="flex flex-col md:flex-row gap-x-6 col-span-full">
                                    {/* job id */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="job-id" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Job ID</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="job-id"
                                                id="job-id"
                                                autoComplete=""
                                                required
                                                value={jobId}
                                                disabled={isLoading}
                                                onChange={(e) => setJobId(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    {/* Status */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="job-status" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Status</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="job-status"
                                                name="job-status"
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                defaultValue="active"
                                                required
                                                value={status}
                                                disabled={isLoading}
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value=""></option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                {/* title */}
                                <div className="col-span-full ">
                                    <label htmlFor="job-title" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Title</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="job-title"
                                            id="job-title"
                                            autoComplete=""
                                            required
                                            value={jobTitle}
                                            disabled={isLoading}
                                            onChange={(e) => setJobTitle(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-x-6 col-span-full">
                                    <div className="md:w-1/2">
                                        <label htmlFor="job-is-volunteer" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Volunteer Opportunity </b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="job-is-volunteer"
                                                name="job-is-volunteer"
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                required
                                                value={isVolunteer}
                                                disabled={isLoading}
                                                onChange={(e) => setIsVolunteer(e.target.value)}
                                            >
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </select>
                                        </div>
                                    </div>


                                    {/* salary -  Compesation */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="job-compesation" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Compensation</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="job-compesation"
                                                id="job-compesation"
                                                required
                                                value={compesation}
                                                disabled={isLoading}
                                                onChange={(e) => setCompesation(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-full md:col-span-3">
                                    <label htmlFor="job-time-type" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Job Type </b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="job-time-type"
                                            name="job-time-type"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            required
                                            value={jobType}
                                            disabled={isLoading}
                                            onChange={(e) => setJobType(e.target.value)}
                                        >
                                            <option value="">Select Job Type</option>
                                            <option value="full-time">Full Time</option>
                                            <option value="part-time">Part Time</option>
                                            <option value="contract-time">Contract</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full md:col-span-3 mt-3 md:mt-0">
                                    <label htmlFor="job-location-type" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Location Type </b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="job-location-type"
                                            name="job-location-type"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            required
                                            value={locationType}
                                            disabled={isLoading}
                                            onChange={(e) => setLocationType(e.target.value)}
                                        >
                                            <option value="">Select Job Location</option>
                                            <option value="on-site">On Site</option>
                                            <option value="Hybrid">Hybrid</option>
                                            <option value="Remote">Remote</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Industry */}
                                <div className="col-span-full md:col-span-3 mt-3 md:mt-2">
                                    <label htmlFor="job-industry" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Industry</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="job-industry"
                                            name="job-industry"
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            required
                                            value={industry}
                                            disabled={isLoading}
                                            onChange={(e) => setIndustry(e.target.value)}
                                        >
                                            <option value="">Select Industry</option>
                                            {Industries.map((ind) => {
                                                return (
                                                    <option value={ind.name} key={ind.name}>{ind.name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                {/* Work location --> state, city, zipcode */}
                                <div className="col-span-full md:col-span-3 mt-3 md:mt-2">
                                    <label htmlFor="work-city" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Work City</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="work-city"
                                            name="work-city"
                                            type="text"
                                            autoComplete=""
                                            required
                                            value={city}
                                            disabled={isLoading}
                                            onChange={(e) => setCity(e.target.value)}
                                            className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        ></input>
                                    </div>
                                </div>

                                <div className="col-span-full md:col-span-3 mt-3 md:mt-2">
                                    <label htmlFor="work-state" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Work State</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="work-state"
                                            name="work-state"
                                            type="text"
                                            autoComplete=""
                                            required
                                            value={state}
                                            disabled={isLoading}
                                            onChange={(e) => setState(e.target.value)}
                                            className=" mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        >
                                            <option value={""} key={"no-key"}>Select State</option>
                                            {states.map((state) => {
                                                return (
                                                    <option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full md:col-span-3 mt-3 md:mt-2 ">
                                    <label htmlFor="work-zipcode" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Zip Code</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="work-zipcode"
                                            name="work-zipcode"
                                            type="text"
                                            autoComplete=""
                                            required
                                            value={zipcode}
                                            disabled={isLoading}
                                            onChange={(e) => setZipcode(e.target.value)}
                                            className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        />
                                    </div>
                                </div>




                                {/* cta */}
                                <div className="col-span-full mt-4">
                                    <label htmlFor="job-cta" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Application Link or Email Address</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="job-cta"
                                            id="job-cta"
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            required
                                            value={callToActionLink}
                                            disabled={isLoading}
                                            onChange={(e) => setCallToActionLink(e.target.value)}
                                        />
                                    </div>
                                </div>



                                {/* details */}
                                <div className="col-span-full my-5">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Job Description</b>
                                        <span className="text-red-900 mx-1">*</span>
                                        {/* <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                                            - Provide details of the job
                                        </span> */}
                                    </label>
                                    <p style={{ color: "gray", fontSize: "12px" }}>
                                        Tips:
                                        Describe the job's duties or what the person will be working on.
                                    </p>
                                    <div className="mt-2">
                                        <Editor
                                            key={'editor4-' + editorKey}
                                            apiKey={TINY_MCE_API_KEY}
                                            onInit={(evt, editor) => editorDetailsRef.current = editor}
                                            onEditorChange={(text, editor) => handleEditorUpdate(text, editor, setDetails)}
                                            value={details}
                                            init={{
                                                height: 250,
                                                menubar: false,
                                                plugins:
                                                    'advlist link autolink lists link image  anchor ' +
                                                    'searchreplace visualblocks ' +
                                                    'media table past wordcount'
                                                ,
                                                toolbar: 'undo redo | link formatselect | fontfamily fontsize | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | outdent indent | ' +
                                                    'bullist numlist',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* skills */}
                                <div className="col-span-full  mt-3 md:mt-4 ">
                                    <label htmlFor="job-skills" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Skills</b>
                                        <span className="text-red-900 mx-1">*</span>
                                        {/* <span className="text-xs">(For better job display, please input Skills as a list)</span> */}
                                    </label>
                                    <p style={{ color: "gray", fontSize: "12px" }}>
                                        Tips:
                                        [For better job display, please input Skills as a list]
                                    </p>
                                    <div className="mt-2">
                                        <Editor
                                            key={'editor1-' + editorKey}
                                            apiKey={TINY_MCE_API_KEY}
                                            onInit={(evt, editor) => editorSkillsRef.current = editor}
                                            onEditorChange={(text, editor) => handleEditorUpdate(text, editor, setSkills)}
                                            value={skills}
                                            init={{
                                                height: 250,
                                                menubar: false,
                                                plugins:
                                                    'advlist link autolink lists link image  anchor ' +
                                                    'searchreplace visualblocks ' +
                                                    'media table past wordcount'
                                                ,
                                                toolbar: 'undo redo | link formatselect | fontfamily fontsize | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | outdent indent | ' +
                                                    'bullist numlist',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
                                            }}
                                        />
                                    </div>
                                </div>



                                {/* qualifications */}
                                <div className="col-span-full my-4">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Qualifications</b>
                                        {/* <span className="text-xs ml-1">(Please input Qualifications as a list)</span> */}
                                    </label>
                                    <p style={{ color: "gray", fontSize: "12px" }}>
                                        Tips:
                                        [Please input Qualifications as a list]
                                    </p>
                                    <div className="mt-2">
                                        <Editor
                                            key={'editor2-' + editorKey}
                                            apiKey={TINY_MCE_API_KEY}
                                            onInit={(evt, editor) => editorQualifaictionsRef.current = editor}
                                            onEditorChange={(text, editor) => handleEditorUpdate(text, editor, setQualifications)}
                                            value={qualifications}
                                            init={{
                                                height: 250,
                                                menubar: false,
                                                plugins:
                                                    'advlist link autolink lists link image  anchor ' +
                                                    'searchreplace visualblocks ' +
                                                    'media table past wordcount'
                                                ,
                                                toolbar: 'undo redo | link formatselect | fontfamily fontsize | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | outdent indent | ' +
                                                    'bullist numlist',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
                                            }}
                                        />
                                    </div>
                                </div>


                                {/* special accomodations */}
                                <div className="col-span-full mt-4">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Special Accommodations</b>
                                        {/* <span className="text-xs ml-1">(For better job display, please input Special Accommodations as a list)</span> */}
                                    </label>
                                    <p style={{ color: "gray", fontSize: "12px" }}>
                                        Tips:
                                        Describe accommodations available for people with special needs or medical conditions. For example: Wheelchair accessible. Additional accommodations can be made as needed. <br />
                                        [For better job display, please input Special Accommodations as a list]
                                    </p>
                                    <div className="mt-2">
                                        <Editor
                                            key={'editor3-' + editorKey}
                                            apiKey={TINY_MCE_API_KEY}
                                            onInit={(evt, editor) => editorSpecialAccomodationsRef.current = editor}
                                            onEditorChange={(text, editor) => handleEditorUpdate(text, editor, setSpecialAccomodations)}
                                            value={specialAccomodations}
                                            init={{
                                                height: 250,
                                                menubar: false,
                                                plugins:
                                                    'advlist link autolink lists link image  anchor ' +
                                                    'searchreplace visualblocks ' +
                                                    'media table past wordcount'
                                                ,
                                                toolbar: 'undo redo | link formatselect | fontfamily fontsize | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | outdent indent | ' +
                                                    'bullist numlist',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
                                            }}
                                        />
                                    </div>
                                </div>



                            </div>

                            <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-center md:justify-end ">

                                <button role="button"
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-full md:w-[200px] flex justify-center rounded-md bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                >
                                    {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                    Submit
                                </button>

                            </div>

                            {errors.length > 0 &&
                                <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                    {errors[0]}
                                </div>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default JobForm;

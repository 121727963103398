import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
// import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/events";
import { getEvents } from "../core/events";
import { isAuthorised } from "../utils/auth";
import EditIcon from "../components/icons/Edit";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import { searchJobs } from '../core/job_listing/index';
import Loader from 'react-loaders';
import { Utils } from "../utils";
import EventsIcon from "../components/icons/EventsIcon";
import ClockIcon from "../components/icons/Clock";
import LocationIcon from "../components/icons/Location";
import GlobeIcon from "../components/icons/Globe";
import LoginAlertModal from "../components/auth/LoginAlertModal";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import DOMPurify from "dompurify";



const Events = () => {
    const navigate = useNavigate();

    let { id } = useParams();
    if (!id) {
        id = "";
    }

    const [ads, setAds] = useState([]);
    const [events, setEvents] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [cleanHtml, setCleanHtml] = useState("");

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const isOrg = isAuthorised(["organisation"]);

    const fetchEvents = async () => {
        setIsLoading(true);

        // let communityBudges = {};
        // if (loggedInUser !== null) {
        //     communityBudges = await getCommunityBudges();
        //     setCommunityBudges(communityBudges);
        // } else {
        //     communityBudges = await getAnonymousCommunityBudges();
        //     setCommunityBudges(communityBudges);
        // }

        const data = await getEvents();
        const eventsData = data?.events ?? [];
        const adsData = data?.ads ?? [];
        setEvents(eventsData.filter((e) => e._id === id));
        setAds(adsData);
        setIsLoading(false);


    }

    const limit = -1;
    const [jobsList, setJobsList] = useState([]);
    const [errors, setErrors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLocationTerm, setSearchLocationTerm] = useState('');
    const [isOnSite, setIsOnSite] = useState('false');
    const [isHybrid, setIsHybrid] = useState('false');
    const [isRemote, setIsRemote] = useState('false');
    const [isFulltime, setIsFulltime] = useState('false');
    const [isPartTime, setIsPartTime] = useState('false');
    const [isContract, setIsContract] = useState('false');
    const [showMySavedJobs, setShowMySavedJobs] = useState('false');
    const [isVolunteer, setIsVolunteer] = useState("no");
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitSearchForm(showMySavedJobs);
        }
    };
    const handleLocationChange = (event) => {
        setSearchLocationTerm(event.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        submitSearchForm(showMySavedJobs);
    }
    const submitSearchForm = async (getMyySavedJobs = 'false') => {
        setErrors([]);
        setIsLoading(true);
        const inputs = {
            searchTerm,
            isOnSite,
            isHybrid,
            isRemote,
            isFulltime,
            isPartTime,
            isContract,
            showMySavedJobs: getMyySavedJobs,
            searchLocationTerm,
            isVolunteer
        };
        let response = await searchJobs(inputs);
        setIsLoading(false);
        if (!Array.isArray(response) || (Array.isArray(response) && response.length > 0 && typeof response[0] === 'string')) {
            return setErrors(response);
        }
        let searchedJobs = response ? response : [];
        if (limit !== -1) {
            searchedJobs = searchedJobs.slice(0, limit - 1);
        }
        setJobsList(searchedJobs);
    }

    useEffect(() => {

        fetchEvents();

    }, []);

    const renderDuration = (event) => {
        let duration = "";
        let days = event.duration?.days ?? 0;
        let hours = event.duration?.hours ?? 0;
        let mins = event.duration?.mins ?? 0;
        if (days > 0) {
            duration = `${days} day${days === 1 ? '' : 's'}`;
        }
        if (hours > 0) {
            duration += `${duration.length > 0 ? ', ' : ''}${hours} hr${hours === 1 ? '' : 's'}`;
        }
        if (mins > 0) {
            duration += `${duration.length > 0 ? ', ' : ''}${mins} min${mins === 1 ? '' : 's'}`;
        }
        if (duration.length == 0) {
            return 'few mins';
        }
        return duration;
    };

    const renderAddress = (event) => {
        let address = event.address;
        if (typeof address === 'object') {
            address = `${address.city}, ${address.state}, ${address.zipcode}`
        }
        if (event.location_type === "virtual" || (typeof address === 'string' && address.length === 0)) {
            address = "Virtual Online Event";
        }
        return address;
    };

    const renderDetails = (event) => {
        let html = DOMPurify.sanitize(event.description, {
            USE_PROFILES: { html: true },
        });

        html = decodeURIComponent(html)
        return html;
    }

    return (
        <MainLayout currentPage={"events"} ads={ads} >
            <div className="flex-grow flex flex-col  ">
                <div className="flex flex-col ">

                    {isLoading &&
                        <>
                            <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                            <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                            <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                        </>
                    }

                    {!isLoading &&
                        <div className="flex flex-col ">

                            <div className={`border-b rounded-t-md border-[#gray] flex flex-row ${isOrg ? "justify-between" : "justify-center"} items-center bg-primary-white flex flex-row flex-wrap text-sm font-bold pt-2 text-purple-900`}>

                                <span className="pl-4">
                                    {isOrg ? `MY` : ``} EVENT DETAILS
                                </span>

                                {isOrg &&
                                    <a
                                        href="/events/add"
                                        className="pointer flex flex-row gap-x-[2px] cursor-pointer rounded-sm text-purple-900 px-3 py-1.5 text-md font-bold hover:text-purple-800 "
                                    >
                                        <EditIcon />
                                        Post Event
                                    </a>
                                }
                            </div>

                            {/* list of events */}
                            <div className={`flex flex-col bg-primary-white h-auto rounded-b-md`}  >
                                {events.length == 0 &&
                                    <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20">
                                        <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                            <div>
                                                No Events
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="rounded-b-md text-black "  >

                                    {events.map((event, index) => (
                                        <div key={index} >
                                            <div className="flex flex-col rounded-b-md border border-[#dae0e6] shadow-md">
                                                <div className="relative">
                                                    {/* {JSON.stringify(event)} */}
                                                    <div className="block w-full cursor-pointer" >
                                                        <img src={event.media} className="w-full rounded-t-md" />
                                                    </div>

                                                    {event.status === "ongoing" &&
                                                        <span className="absolute  top-2 right-2 bg-green-200 text-green-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                            On going
                                                        </span>
                                                    }
                                                    {event.status === "pending" &&
                                                        <span className="absolute  top-2 right-2 bg-purple-200 text-purple-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                            Up coming
                                                        </span>
                                                    }
                                                    {event.status === "done" &&
                                                        <span className="absolute  top-2 right-2 bg-red-200 text-yellow-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                            Ended
                                                        </span>
                                                    }
                                                </div>
                                                <div className="p-2 rounded-b-md flex flex-col">
                                                    <div className="flex flex-row items-start justify-start gap-x-2">
                                                        <img
                                                            className="inline-block  w-[48px] h-[48px] max-w-[48px] max-h-[48px] rounded-sm"
                                                            src={event.user.avatar}
                                                            alt=""
                                                        />
                                                        <div className="flex flex-col justify-between gap-x-1 flex-grow gap-y-1">
                                                            <h4 className="text-sm font-semibold font-medium ">
                                                                <a className="block w-ful cursor-pointer" href={event.external_link} target="_blank">
                                                                    {event.title}
                                                                </a>
                                                            </h4>
                                                            <span className="text-sm flex flex-row items-start justify-start md:items-center ">
                                                                <span className="text-gray-400 mt-[3px] md:mt-0 mr-1">
                                                                    <LocationIcon />
                                                                </span>
                                                                <span>
                                                                    {renderAddress(event)}
                                                                </span>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full px-2 gap-y-1" >
                                                    <div className="flex flex-col md:flex-row items-start md:items-center text-xs flex-grow gap-y-1 " >
                                                        <div className="flex flex-row items-center text-xs gap-x-1  capitalize mr-4">
                                                            <span>{(event?.event_type ?? "").replace("_", " ")}, </span>
                                                            <span>{event.location_type}</span>
                                                        </div>

                                                        <div className="flex flex-row items-center">
                                                            <div className="flex flex-row items-end">
                                                                <span className="text-gray-400 mr-1">
                                                                    <EventsIcon />
                                                                </span>
                                                                <span className="inline-block">
                                                                    Starts
                                                                </span>
                                                            </div>
                                                            <span className="text-purple-900 ml-1">
                                                                {`${Utils.formatDate(event.start_date, "month date, year hrs:mins")} ${event.timezone}`}
                                                            </span>
                                                        </div>
                                                        {/* <span className="text-xs hidden md:inline-block px-2">
                                                                    -
                                                                </span> */}
                                                        <div className="flex flex-row items-center">
                                                            <div className="flex flex-row items-center md:ml-3">
                                                                <span className="text-gray-400 mr-1">
                                                                    <EventsIcon />
                                                                </span>
                                                                <span className="inline-block">
                                                                    Ends
                                                                </span>
                                                            </div>
                                                            <span className="text-purple-900 ml-1">
                                                                {`${Utils.formatDate(event.end_date, "month date, year hrs:mins")} ${event.timezone}`}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* <div className="flex flex-row items-center justify-end ml-0 md:ml-3 flex-grow">
                                                                <span className="text-gray-400 mr-0 ">
                                                                    <GlobeIcon />
                                                                </span>
                                                                <span className="text-purple-900 ml-1 text-xs">
                                                                    {event.timezone}
                                                                </span>
                                                            </div> */}
                                                </div>

                                                <div className="flex flex-col md:flex-row justify-start items-start md:items-center px-2 pt-0 md:pt-2 gap-y-1">
                                                    <div className="flex flex-row items-center justify-end ">
                                                        <span className="text-gray-400 mr-1">
                                                            <ClockIcon />
                                                        </span>
                                                        <span className="text-xs">
                                                            Duration
                                                        </span>
                                                        <span className="text-purple-900 ml-1 text-xs">
                                                            {renderDuration(event)},
                                                        </span>
                                                    </div>
                                                    {event?.is_recurring?.toString() != "true" &&
                                                        <div className="flex flex-row items-center justify-end ml-0 md:ml-3 ">
                                                            <span className="text-xs inline-block">
                                                                Hosted By:
                                                            </span>
                                                            <span className="text-purple-900 ml-1 text-xs">
                                                                {(event?.host_name?.length ?? 0) > 0 ? event?.host_name : event?.user?.username}
                                                            </span>
                                                        </div>
                                                    }
                                                    {event?.is_recurring?.toString() === "true" &&
                                                        <div className="flex flex-row items-center justify-end ml-0 md:ml-3 ">
                                                            <span className="text-xs inline-block">
                                                                Occurs Every:
                                                            </span>
                                                            <span className="text-purple-900 ml-1 text-xs truncate max-w-[400px]" >
                                                                {event?.recurring_every}
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                {event?.is_recurring?.toString() === "true" &&
                                                    <div className="flex flex-col md:flex-row justify-start items-start md:items-center px-2 pt-0 md:pt-2 gap-y-1">
                                                        <div className="flex flex-row items-center justify-end ml-0 md:ml-0">
                                                            <span className="text-xs inline-block">
                                                                Hosted By:
                                                            </span>
                                                            <span className="text-purple-900 ml-1 text-xs">
                                                                {(event?.host_name?.length ?? 0) > 0 ? event?.host_name : event?.user?.username}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="p-2 rounded-b-md flex flex-col ">
                                                    {/* <p className="text-sm">{event.description}</p> */}
                                                    <div className="parsed-html fs-14px" style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: renderDetails(event) }}></div>
                                                </div>
                                                <div className="flex flex-row items-center  ml-0 p-2 pt-0 gap-x-1">
                                                    <span className="text-xs inline-block text-xs">
                                                        Go to event: <a href={event.external_link} target="_blank" className="text-xs text-purple-900 ml-1">{event.external_link}</a>
                                                    </span>
                                                </div>
                                                {(event.user._id === (loggedInUser?._id ?? "")) &&
                                                    <div className="flex flex-row items-center justify-end p-2">
                                                        <a role="button"
                                                            aria-label="edit event button"
                                                            href={`/events/edit/${event._id}`}
                                                            className="rounded-sm text-purple-700 text-sm font-semibold  hover:text-purple-800 flex flex-row items-center "
                                                        >
                                                            <EditIcon /> Edit Event
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    }

                </div>
            </div>
        </MainLayout>
    );
};

export default Events;

